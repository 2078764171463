
// Dynamic ROUTING
export const httpUserRouting= '/auth/user-permission-pages'

export const httpVerifyToken = '/api/admin/verify-token'

// dashboard api
export const httpDashboardData = '/api/admin/get-dashboard-matrix'

// AUTH
export const httpManageLogin = '/api/admin/user-login'
export const httpManageRegister = ''

// ADMIN - USERS
export const httpGetUsers = '/api/admin/get-all-admin-users'
// export const getPermissionsbyID = '/api/admin/get-all-navigation-by-user-id-to-assign-permission'
export const getPermissionsbyID = '/api/admin/formatted-navbar'
export const httpPutPermissions = '/api/admin/update-user-navigation-by-user-id-to-assign-permission'
export const httpPutUsers = '/api/admin/update-admin'
export const httpChangeAdminUserStatus = '/api/admin/change-admin-account-status'
export const httpAddUser = '/api/admin/user-registration'

// enterprise 
export const httpGetEnterprise = '/api/admin/enterprises'
export const httpPostEnterprise = '/api/admin/enterprise-registration'
export const httpPutEnterprise = '/api/admin/enterprise'
export const httpChangeEntpStatus = '/api/admin/change-status'

// dep by enterprise id
export const httpGetDepartmentByID = '/api/admin/get-dep-by-enterpriseid'
// manager by enterprise id
export const httpGetManagerByID = '/api/admin/get-managers-by-enterprise-id'

//  ENTERPRISE - USER
export const httpEnterpriseUser = '/api/admin/users'
export const httpChangeUserStatus = '/api/admin/change-user-status'

// package individual
export const httpGetSubscriptions = '/api/admin/subscriptions'

// package enterprise
export const httpGetPackageEntp = '/api/admin/subscriptions'

export const httpManagerUsersTemp = "/api/admin/update-bulk-users-for-templates-update"


// get users by manager ID 
export const httpGetUSERSByManagerID = '/api/admin/get-user-by-manager-id'

// get users by department ID
export const httpGetUSERSByDepID = '/api/admin/get-user-by-manager-id'

// REIVEWS

// department
export const httpGetDepartment = '/api/admin/departments'


// manager
export const httpGetManager = '/api/admin/managers'
export const httpGetCategories = '/api/admin/title-categories'
export const httpChangeManagerStatus = '/api/admin/change-manager-status'


 export const httpGetReviews = '/api/admin/reviews'
 export const httpGetReviewsBySMEID = '/api/admin/get-reviews-by-sme-id'


 //  SME aDMIN
 export const httpGetSME = '/api/admin/sme'
 export const httpSMERating = '/api/admin/get-avg-review-of-cat-by-sme-id'
 export const httpUpdateSME = '/api/admin/sme'
 
 export const httpChangeSMEStatus = '/api/admin/change-sme-status'

export const httpIndividualUser = '/api/admin/indv-users'

export const httpSMECategory = '/api/admin/title-categories'



// export const httpSMEAdminTitle = '/api/admin/get-titles-by-admin'
export const httpSMEAdminTitle = '/api/admin/get-titles-by-admin-by-pagination'


export const httpSMESMETitle = '/api/admin/get-titles-by-smeid'

export const httpTITLEStatus = '/api/admin/titles'

export const httpRemarks = '/api/admin/remarks'

export const updateSMEReferences = '/api/admin/update-reference'
export const updateSMETakeaways = '/api/admin/update-takeaways'
export const deleteSMEReferences = '/api/admin/delete-reference'
export const deleteSMETakeaways = '/api/admin/delete-takeaways'

export const httpUpdateAdminTags = '/api/admin/update-title-tags'

// fcm settings
export const httpFCMSettings = '/api/admin/predefined_messages'

export const httpGetSettings = '/api/admin/settings'

export const httpWebsiteLogo = '/api/admin/settings-image'

export const httpLanguageSettings = '/api/admin/languages'
export const httpLanguagePUTPOST = '/api/admin/language'
export const httpLangStatus = '/api/admin/change-language-status'

export const httpRewards = '/api/admin/get-rewards'
export const httpCreateReward = '/api/admin/create-reward'
export const httpUpdateReward = '/api/admin/update-reward'
export const httpDeleteReward = '/api/admin/delete-reward'

export const httpCharity = '/api/admin/charityPrograms'

export const httpUserRewards = '/api/admin/rewards'

export const httpSubCategories = '/api/admin/get-sub-category-by-id'
export const httpAddSubCat = '/api/admin/add-sub-categories'
export const httpUpdateSubCat = '/api/admin/update-sub-category'

export const httpSubSubCategories = '/api/admin/title-sub-sub-categories'
export const httpAddAddSubCat = '/api/admin/title-sub-sub-categories'
export const httpUpdateUpSubCat = '/api/admin/title-sub-sub-categories'

export const httpGetSubSubBySMEID = '/api/admin/title-sub-sub-categories-by-sme-id'

export const httpDeleteSubscription = "/api/admin/delete-subscription"
export const httpDeleteSME = "/api/admin/delete-sme"

export const httpPassword = '/api/admin/reset-password'


export const httpGetAllActiveCategories = '/api/admin/get-active-categories'

export const httpTags = '/api/admin/tags'

// export const httpGetLibraries = '/api/admin/mb-get-titles-by-userID-for-library'
export const httpGetLibraries = '/api/admin/mb-get-titles-for-library-by-pagination'
// export const httpGetManagerLibraries = '/api/admin/mb-get-titles-by-userID-for-library'
export const httpGetManagerLibraries = '/api/admin/mb-get-titles-for-library-by-pagination'

export const httpGetUserLibReviewsNotes = '/api/admin/get-lib-notes-and-reveiw-by-id'

// admin title
export const httpAdminFlags = '/api/admin/get-count-of-all-lib'


export const httpGetformdata = '/api/admin/user-first-time-settings-update'
export const httpGetSchedule = '/api/admin/get-schedule'

// upload bulk users in manager
export const httpBulkUsers = "/api/admin/add-bulk-users"

export const httpGetAllTemplates = "/api/admin/get-templates-By-id"
export const httpUpdateTemplate = "/api/admin/creating-and-updating-schedule-templates"

export const httpForgetPassMail = "/sendmail-for-forget-password"
export const httpUpdateUserTemplate = "/api/admin/change-user-template"
export const httpUpdateManagerTemplate = "/api/admin/change-manager-template"


export const httpSMECount = "/api/admin/get-count-of-all-lib-for-sme"

export const httpDeleteCategories = '/api/admin/title-categories'
export const httpDeleteSubCategories = '/api/admin/title-sub-categories'
export const httpDeleteSubSubCategories = '/api/admin/title-sub-sub-categories'
export const httpDeleteLanguage = '/api/admin/language'
export const httpDeleteDraftTitle = '/api/admin/titles'

export const httpCategoryTree = "/api/admin/get-category-tree"

export const httpDeleteTemplate = "/api/admin/delete-template"


// delete admin user
export const httpDeleteAdminUser = '/api/admin/admin-user'
export const httpDeleteEntpUser = '/api/admin/delete-user'

export const httpDeleteAdminTags = "/api/admin/delete-tags"
export const httpDeleteEnterprise = "/api/admin/delete-enterprise"
export const httpDeleteManager = "/api/admin/delete-manager"


/////////////////////////////////////////////////////
/////////////////////////////////////////////////////
/////////////////////////////////////////////////////
////////////////////  CHAT  ////////////////////////
/////////////////////////////////////////////////////
/////////////////////////////////////////////////////

export const httpGetUserListInbox = '/api/chat/get-inbox-chat-list'
export const httpGetChatByUserId = '/api/chat/get-chat-by-user-id'
export const httpPostSendMessage = '/api/chat/send-message'



//////////////////////////////////////////
// //////////////Notification////////////
export const httpGetNotifications = '/api/admin/get-notifications'
export const httpUpdateNotifications = '/api/admin/update-notifications'


export const httpTutorialChangeBulkStatus = '/api/admin/update-selected-titlestatus'


// run cron job
export const httpCron = 'api/admin/run-schedule-crone'